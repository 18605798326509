/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2020 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Radomir Tinkov
 * License URL: https://www.fontspring.com/licenses/radomir-tinkov/webfont
 *
 *
 */

@font-face {
  font-family: Gilroy;
  src: url("components/static/gilroy.woff2")
}
@font-face {
  font-family: GilroyLight;
  src: url("components/static/gilroylight.woff2")
}